import "./Subscription.css";
import React, { useState } from "react";
import { getUserLocation } from "../../redux/auth";
import { useDispatch, useSelector } from "react-redux";
import LocationsModal from "../../components/Modals/LocationsModal/LocationsModal";
import { applyInterFontToDigits } from "../CropDetails/CropDetails";
import BackHeader from "../../components/BackHeader";
import { Drawer } from "@mantine/core";
import PaymentMethods from "./components/PaymentMethods";
import { Modal } from "react-bootstrap";
import ConfirmPaymentModal from "./components/ConfirmPaymentModal";
import LoadingModal from "./components/LoadingModal";
import { Navigate } from "react-router-dom";

const freeDetails = [
  "کوئی سی ایک فصل کی ایڈوائزری دیکھ سکتے ہیں۔",
  "تمام ویڈیوز دیکھ سکتے ہیں۔",
  "ملک بھر کے موسم کی صورتحال جان سکتے ہیں۔"
]

const proDetails = [
  "تمام فصلوں کی ایڈوائزری دیکھ سکتے ہیں۔",
  "تمام ویڈیوز دیکھ سکتے ہیں۔",
  "ملک بھر کے موسم کی صورتحال جان سکتے ہیں۔"
]

const Subscription = () => {
  const dispatch = useDispatch();
  const authStore = useSelector((state) => state.auth);
  const subscriber = useSelector((state) => state.Subscription?.subscriberDetails?.data);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [paymentMethod, setPaymentmethod] = useState(null)
  const [loadingModal, setLoadingModal] = useState(false)

  const handleGetLocation = () => {
    dispatch(getUserLocation());
  };

  return <Navigate to="/home" />

  return (
    <>
      <div className="subscription-container">
        <BackHeader name={"سبسکرائب کریں"} />
        <p className="text-right text-[14px] leading-8 -mt-2">آپ فصل سے متعلق تمام معلومات حاصل کر سکتے ہیں۔</p>

        <div className={`free-subscription-section ${(subscriber?.hasService !== "Y" && subscriber?.hasGrace !== "Y") ? "border-[4px] border-[#007F3B]" : "opacity-50"}`}>
          <div className="flex flex-col items-end">
            <div className="bg-[#ECF9FF] rounded-[8px] w-20 h-[28px] flex justify-center items-center">
              <p className="text-[12px] text-[#0F2532] mt-[10px]">مفت پلان</p>
            </div>
            <div className="flex items-center">
              {/* <p className="text-[14px] text-[#777777] mt-1">مہینہ/</p> */}
              <p className="text-[#0F2532] text-[18px] font-bold pt-1">چارجز کے بغیر</p>
            </div>
          </div>
          {/* <div className="subscription-header">
            <h3 className="font-[inter] text-[16px] font-semibold">Free</h3>
            <h3 className="flex items-center gap-1">
              <span className="blacktext" dangerouslySetInnerHTML={{ __html: applyInterFontToDigits("Rs 0") }} />
              <span className="graytext">/Month</span>
            </h3>
          </div> */}
          <button onClick={() => {
            // dispatch(setSubscriptionType(2))
          }}
            className="freebtn-section"
            // to="/verification"
            disabled
          >
            <div className="free-btn">مفت میں شروع کریں</div>
          </button>

          <div className="subscription-details">
            {
              freeDetails.map((item, index) => (
                <div key={index} className="subscription-title">
                  <img src="green-rounded-tick.svg" className="ml-1" alt="check" width={12} />
                  <h5>{item}</h5>
                </div>
              ))
            }
          </div>
        </div>

        <div className={`pro-subscription-section ${(subscriber?.hasService === "Y" || subscriber?.hasGrace === "Y") && "border-[4px] border-[#007F3B]"}`}>
          <div className="flex flex-col items-end">
            <div className="bg-[#FFD705] rounded-[8px] w-20 h-[28px] flex justify-center items-center">
              <p className="text-[12px] text-[#0F2532] mt-[10px]">پریمیم پلان</p>
            </div>
            <div className="flex items-center">
              <p className="text-[14px] text-[#777777] mt-1">روزانہ/</p>
              <p className="text-[#007F3B] text-[18px] font-bold">روپے</p> &nbsp;<span className="text-[#007F3B] mb-1" dangerouslySetInnerHTML={{ __html: applyInterFontToDigits("0.20") }} />
            </div>
          </div>
          {/* <div className="pro-subscription-header">
            <h3 className="font-[inter] text-[16px] font-semibold">Paid</h3>
            <h3 className="flex items-center gap-1">
              <span className="greentext" dangerouslySetInnerHTML={{ __html: applyInterFontToDigits("Rs 0.20") }} />
              <span className="graytext">/ Month</span>
            </h3>
          </div> */}
          <button
            disabled={subscriber?.hasService === "Y" || subscriber?.hasGrace === "Y"}
            onClick={() => setDrawerOpen(true)}
            className={`probtn-section w-full ${(subscriber?.hasService === "Y" || subscriber?.hasGrace === "Y") && "cursor-not-allowed opacity-50"}`}
          >
            <div className="pro-btn">سبسکرائب کریں</div>
          </button>

          <div className="subscription-details">
            {
              proDetails.map((item, index) => (
                <div key={index} className="subscription-title">
                  <img src="green-rounded-tick.svg" className="ml-1" alt="check" width={12} />
                  <h5>{item}</h5>
                </div>
              ))
            }
          </div>

        </div>
      </div>

      {/* Payment Method drawer */}
      <Drawer
        opened={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        radius="md"
        position="bottom"
        // size={paymentMethod ? "350" : "200"}
        size={paymentMethod ? "280" : "120"}
        withCloseButton={false}
        padding="lg"
      >
        <PaymentMethods
          paymentMethod={paymentMethod}
          setPaymentmethod={setPaymentmethod}
          cellNo={authStore?.authData?.msisdn}
          setConfirmationModalOpen={setConfirmationModalOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Drawer>

      {/* Confirmation dialog to subscribe premium */}
      <Modal
        show={confirmationModalOpen}
        onHide={() => setConfirmationModalOpen(false)}
        centered
      >
        <ConfirmPaymentModal
          cellNo={authStore?.authData?.msisdn}
          setConfirmationModalOpen={setConfirmationModalOpen}
          setDrawerOpen={setDrawerOpen}
          setLoadingModal={setLoadingModal}
        />
      </Modal>

      {/* Loading modal */}
      <Modal
        show={loadingModal}
        onHide={() => setLoadingModal(false)}
        centered
      >
        <Modal.Body>
          <LoadingModal
            title="تصدیق کا انتظار ہے۔"
            description="آپ کے ادائیگی کے چینل سے اس کی منظوری کے
                بعد آپ خدمات حاصل کرنے میں کامیابی سے"
          />
        </Modal.Body>
      </Modal>

      {
        authStore?.subscriberDetails?.data?.isSubscriber === "N" &&
        <LocationsModal
          show={authStore?.isLocationLoading}
          // onHide={() => setModalShow(false)}
          getLocation={handleGetLocation}
        />
      }

      {/* Confirmation dialog to subscribe premium */}
      {/* <ConfirmationDialog
        show={confirmationModalOpen}
        handleClick={handlePremiumSubscription}
        onHide={() => setConfirmationModalOpen(false)}
        content={"کیا آپ سبسکرائب کرنا چاہتے ہیں؟"}
      /> */}
    </>
  );
};

export default Subscription;
