import "./PersonalInfo.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SpinnerComponent from "../../components/Spinner/Spinner";
import { getSubscriberDetails } from "../Subscription/store";
import { deleteAllCrops, getDistricts, getProvinces, getTehsils, updateSubscriber } from "../../API";
import BackHeader from "../../components/BackHeader";
import { Modal } from "react-bootstrap";
import { clearCrops } from "../Home/CropSection/store";
import ReactGA from "react-ga4";

function Personalinfo() {
  const authStore = useSelector((state) => state.auth);
  const subsStore = useSelector((state) => state.Subscription);
  const cropStore = useSelector(state => state.CropsStore)
  const [userConsentModal, setUserConsentModal] = useState(false)
  const [data, setData] = useState(null)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [province, setProvince] = useState(subsStore.subscriberDetails.data?.province || "")
  const [provinceUrdu, setProvinceUrdu] = useState(subsStore?.subscriberDetails?.data?.provinceUrdu || "")
  const [district, setDistrict] = useState(subsStore.subscriberDetails.data?.district || "")
  const [districtUrdu, setDistrictUrdu] = useState(subsStore.subscriberDetails.data?.districtUrdu || "")
  const [tehsil, setTehsil] = useState(subsStore.subscriberDetails.data?.tehsil || "")
  const [tehsilUrdu, setTehsilUrdu] = useState(subsStore.subscriberDetails.data?.tehsilUrdu || "")
  const [latLng, setLatLng] = useState(null)

  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [tehsils, setTehsils] = useState([])

  useEffect(() => {
    if (provinces.length > 0) return
    getProvinces()
      .then(res => {
        setProvinces(res.data.data)
        const isProvinceExist = res.data.data.some(item => item.province === province)
        if (!isProvinceExist) setProvince(res.data.data[0].province)
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    setProvinceUrdu(provinces.find(item => item.province === province)?.provinceUrdu)
    getDistricts(province)
      .then(res => {
        setDistricts(res.data.data)
        const isDistrictExist = res.data.data.some(item => item.district === district)
        if (!isDistrictExist) setDistrict(res.data.data[0].district)
      })
      .catch(err => console.log(err))
  }, [provinces, province])

  useEffect(() => {
    setDistrictUrdu(districts.find(item => item.district === district)?.districtUrdu)
    getTehsils(district)
      .then(res => {
        setTehsils(res.data.data)
        const isTehsilExist = res.data.data.some(item => item.tehsil === tehsil)
        if (!isTehsilExist) setTehsil(res.data.data[0].tehsil)
      })
      .catch(err => console.log(err))
  }, [districts, district])

  useEffect(() => {
    setTehsilUrdu(tehsils.find(item => item.tehsil === tehsil)?.tehsilUrdu)
    if (tehsils.length === 0) return
    const selectedTehsil = tehsils.find(item => item.tehsil === tehsil)
    setLatLng({ lat: selectedTehsil?.lat, lng: selectedTehsil?.lng })
  }, [tehsils, tehsil])


  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
  } = useForm({
    defaultValues: {
      fullName: subsStore.subscriberDetails.data?.fullName || "",
      address: subsStore.subscriberDetails.data?.address || "",
      province: subsStore.subscriberDetails.data?.provinceUrdu || "",
      district: subsStore.subscriberDetails.data?.districtUrdu || "",
      tehsil: subsStore.subscriberDetails.data?.tehsilUrdu || "",
    }
  });
  if (tehsil === "") return <div>
    <SpinnerComponent />
  </div>

  const update = (d) => {
    updateSubscriber(d || data)
      .then(res => {
        dispatch(getSubscriberDetails({ cellno: authStore?.authData?.msisdn }));
        if (res.data.success)
          navigate("/home")
      })
      .catch(err => console.log(err))
  }

  const deleteCropsAndChangeLocation = () => {
    deleteAllCrops(authStore?.authData?.msisdn).then(() => {
      dispatch(clearCrops());
      update();
    })
      .catch((error) => {
        console.log("Error deleting crops -> ", error)
      })
      .finally(() => {
        setUserConsentModal(false);
      })
  }

  const onSubmit = (data) => {
    delete data.phone
    data.msisdn = authStore?.authData?.msisdn
    data.lat = latLng.lat
    data.lng = latLng.lng
    data.province = province
    data.provinceUrdu = provinceUrdu
    data.district = district
    data.districtUrdu = districtUrdu
    data.tehsilUrdu = tehsilUrdu
    data.tehsil = tehsil
    // console.log("data >>>>>>>>> ", data)
    setData(data)

    ReactGA.event("Profile Updated", {
      label: "Profile Updated",
      action: "click",
      category: "profile",
      user_msisdn: data?.msisdn || null,
      user_name: data?.fullName || null
    });

    // check if province is changed
    if (subsStore.subscriberDetails.data?.province !== province && cropStore?.subscriberCrops?.length > 0) {
      // alert("You can't change your province")
      setUserConsentModal(true)
      return;
    }

    update(data)
  }

  return (
    <>
      <div className="container" id="profile-info-page">
        {/* Header Section */}
        <BackHeader name={"ذاتی معلومات"} />

        {/* Form Section */}
        <form onSubmit={handleSubmit(onSubmit)} className="sub-container">
          <div className="number-section">
            {/* Full Name */}
            <h6 className="number-text">پورا نام </h6>
            <div className="input-wrapper">
              <input
                className={`number-field ${errors.fullName && "outline-red-500"}`}
                placeholder="نام درج کریں۔"
                {...register("fullName",
                  //    {
                  //   // required: "نام درکار ہے۔",
                  //   validate: value =>
                  //     // no special characters but urdu and english characters are allowed
                  //     /^[A-Za-z\s\u0600-\u06FF]+$/.test(value)
                  //     || "نام میں صرف حروف ہونے چاہیے۔"
                  // }
                )}
              // value={inputValue}
              // onChange={handleInputChange}
              ></input>
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00002 8.49935C7.26669 8.49935 6.63891 8.23824 6.11669 7.71602C5.59446 7.19379 5.33335 6.56602 5.33335 5.83268C5.33335 5.09935 5.59446 4.47157 6.11669 3.94935C6.63891 3.42713 7.26669 3.16602 8.00002 3.16602C8.73335 3.16602 9.36113 3.42713 9.88335 3.94935C10.4056 4.47157 10.6667 5.09935 10.6667 5.83268C10.6667 6.56602 10.4056 7.19379 9.88335 7.71602C9.36113 8.23824 8.73335 8.49935 8.00002 8.49935ZM2.66669 13.8327V11.966C2.66669 11.5882 2.76402 11.2409 2.95869 10.924C3.15291 10.6076 3.41113 10.366 3.73335 10.1993C4.42224 9.8549 5.12224 9.59646 5.83335 9.42402C6.54446 9.25202 7.26669 9.16602 8.00002 9.16602C8.73335 9.16602 9.45558 9.25202 10.1667 9.42402C10.8778 9.59646 11.5778 9.8549 12.2667 10.1993C12.5889 10.366 12.8471 10.6076 13.0414 10.924C13.236 11.2409 13.3334 11.5882 13.3334 11.966V13.8327H2.66669ZM4.00002 12.4993H12V11.966C12 11.8438 11.9696 11.7327 11.9087 11.6327C11.8474 11.5327 11.7667 11.4549 11.6667 11.3993C11.0667 11.0993 10.4611 10.8742 9.85002 10.724C9.23891 10.5742 8.62224 10.4993 8.00002 10.4993C7.3778 10.4993 6.76113 10.5742 6.15002 10.724C5.53891 10.8742 4.93335 11.0993 4.33335 11.3993C4.23335 11.4549 4.15291 11.5327 4.09202 11.6327C4.03069 11.7327 4.00002 11.8438 4.00002 11.966V12.4993ZM8.00002 7.16602C8.36669 7.16602 8.68069 7.03535 8.94202 6.77402C9.20291 6.51313 9.33335 6.19935 9.33335 5.83268C9.33335 5.46602 9.20291 5.15224 8.94202 4.89135C8.68069 4.63002 8.36669 4.49935 8.00002 4.49935C7.63335 4.49935 7.31958 4.63002 7.05869 4.89135C6.79735 5.15224 6.66669 5.46602 6.66669 5.83268C6.66669 6.19935 6.79735 6.51313 7.05869 6.77402C7.31958 7.03535 7.63335 7.16602 8.00002 7.16602Z"
                  fill="#D3D3D3"
                />
              </svg>
            </div>
            {errors.fullName && <p className="text-right text-red-500">{errors.fullName.message}</p>}

            {/* Phone Number */}
            <h6 className="number-text">فون نمبر</h6>
            <div className="input-wrapper">
              <input
                className={`number-field ${errors.phone && "outline-red-500"} digit`}
                placeholder="+92 331 000 000"
                value={authStore?.authData?.msisdn}
                // {...register("phone", { required: "فون نمبر درکار ہے۔" })}
                disabled
                style={{
                  direction: "rtl",
                  paddingLeft: "30px",
                }}
              />
              <svg
                style={{
                  left: "auto",
                  right: "10px",
                  rotate: "250deg",
                }}
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3161_10501)">
                  <path
                    d="M4.41333 7.69333C5.37333 9.58 6.92 11.12 8.80667 12.0867L10.2733 10.62C10.4533 10.44 10.72 10.38 10.9533 10.46C11.7 10.7067 12.5067 10.84 13.3333 10.84C13.7 10.84 14 11.14 14 11.5067V13.8333C14 14.2 13.7 14.5 13.3333 14.5C7.07333 14.5 2 9.42667 2 3.16667C2 2.8 2.3 2.5 2.66667 2.5H5C5.36667 2.5 5.66667 2.8 5.66667 3.16667C5.66667 4 5.8 4.8 6.04667 5.54667C6.12 5.78 6.06667 6.04 5.88 6.22667L4.41333 7.69333Z"
                    fill="#D3D3D3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3161_10501">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>

            </div>
            {/* {errors.phone && <p className="text-right text-red-500">{errors.phone.message}</p>} */}



            {/* Province */}
            <h6 className="number-text">صوبہ </h6>
            <div className="dropdown-input-container">
              {province && <select
                className={`dropdown-input-field ${errors.province && "outline-red-500"}`}
                {...register("province",
                  // { required: "صوبہ درکار ہے۔" }
                )}
                onChange={(e) => setProvince(e.target.value)}
                value={province}
              >
                {
                  provinces?.map((item, index) => (
                    <option key={index} value={item.province}>{item.provinceUrdu}</option>
                  ))
                }
              </select>}
            </div>
            {errors.province && <p className="text-right text-red-500">{errors.province.message}</p>}

            {/* Tehsil */}
            <h6 className="number-text mt-3">شہر</h6>
            <div className="dropdown-input-container">

              <select className={`dropdown-input-field ${errors.district && "outline-red-500"}`}
                {...register("district",
                  // { required: "تحصیل درکار ہے۔" }
                )}
                onChange={(e) => setDistrict(e.target.value)}
                value={district}
              >
                {
                  districts?.map((item, index) => (
                    <option key={index} value={item.district}>{item.districtUrdu}</option>
                  ))
                }
              </select>
            </div>
            {errors.district && <p className="text-right text-red-500">{errors.district.message}</p>}

            {/* City */}
            <h6 className="number-text mt-4">تحصیل</h6>
            <div className="dropdown-input-container">
              <select className={`dropdown-input-field ${errors.city && "outline-red-500"}`}
                {...register("tehsil",
                  //  { required: "شہر درکار ہے۔" }
                )}
                onChange={(e) => setTehsil(e.target.value)}
                value={tehsil}
              >
                {
                  tehsils?.map((item, index) => (
                    <option key={index} value={item.tehsil}>{item.tehsilUrdu}</option>
                  ))
                }
              </select>
            </div>
            {errors.tehsil && <p className="text-right text-red-500">{errors.tehsil.message}</p>}

            {/* Address */}
            <h6 className="number-text">پتہ </h6>
            <div className="input-wrapper">
              <input
                className={`address-field ${errors.address && "outline-red-500"}`}
                placeholder="اپنا پتہ درج کریں۔"
                {...register("address",
                  // { required: "پتہ درکار ہے۔" }
                )}
              // value={inputValue}
              // onChange={handleInputChange}
              ></input>
              <svg
                className="address-svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3267_11479)">
                  <path
                    d="M2 11.5003V14.0003H4.5L11.8733 6.62695L9.37333 4.12695L2 11.5003Z"
                    fill="#D3D3D3"
                  />
                  <path
                    d="M13.8067 3.75305L12.2467 2.19305C11.9867 1.93305 11.5667 1.93305 11.3067 2.19305L10.0867 3.41305L12.5867 5.91305L13.8067 4.69305C14.0667 4.43305 14.0667 4.01305 13.8067 3.75305Z"
                    fill="#D3D3D3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3267_11479">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            {errors.address && <p className="text-right text-red-500">{errors.address.message}</p>}

          </div>

          <button className="submit-btn"
            type="submit"
          // disabled={isButtonDisabled}
          >
            جمع کرائیں
          </button>
        </form>
      </div>




      {/* Confirmation dialog to remove crop */}
      <Modal
        show={userConsentModal}
        onHide={() => setUserConsentModal(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="confirmation-dialog"
      >
        <Modal.Body>
          <div className='flex justify-center'>
            <p className="description leading-9 w-52">یہ عمل آپ کی تمام فصلوں کو ہٹا دے گا۔</p>
          </div>
          <div className="flex items-center justify-between gap-2">
            <button onClick={() => setUserConsentModal(false)} className="w-full h-full modal-cancel-btn-black">منسوخ کریں</button>
            <Link onClick={deleteCropsAndChangeLocation} className="w-full no-underline modal-cancel-btn text-center">ٹھیک ہے</Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Personalinfo;
