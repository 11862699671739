import "./CropDetailsSolution.css";
import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import fieldImage from "../../assets/images/field.jpeg";
import GenericModal from "../../components/Modals/GenericModal/GenericModal";
import { useLocation, useNavigate } from "react-router-dom";
import CropSolutionVideo from "./VideoComponent/CropSolutionVideo";
import LazyVideo from "../Home/components/LazyVideo";
import { Divider, Image } from "@mantine/core";
import ReactGA from "react-ga4"
import { useSelector } from "react-redux";

const CropDetailsDisease = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [Images, setImages] = useState([])
  const { challenge, cropName } = location.state;
  const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;
  const [instructionModal, setInstructionModal] = useState(false);
  const openInstructionModal = () => setInstructionModal(true);
  const closeInstructionModal = () => setInstructionModal(false);
  const authStore = useSelector((state) => state.auth);

  useEffect(() => {
    let img = JSON.parse(challenge.image)
    setImages(img)
  }, [])
  return (
    <div className="crop-details-disease-page">
      {/* Carousel Section */}
      <div className="carousel-container">
        <svg onClick={() => navigate(-1)} className="back-btn cursor-pointer" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="20" fill="#0F2532" />
          <g clipPath="url(#clip0_811_8632)">
            <path d="M28 19H15.83L21.42 13.41L20 12L12 20L20 28L21.41 26.59L15.83 21H28V19Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_811_8632">
              <rect width="24" height="24" fill="white" transform="translate(8 8)" />
            </clipPath>
          </defs>
        </svg>

        <Carousel>
          {Images.map((image, index) => (
            <Carousel.Item key={index} interval={1000}>
              <Image
                src={`${baseURL}/uploads/${image}`}
                alt={image}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = fieldImage;
                }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <div className="flex flex-col items-end mt-3 gap-3">
        <h2>علامات سنیں</h2>
        <audio id="myAudio" controls
          onPlay={() => {
            ReactGA.event("Audio Played", {
              category: "Audio",
              action: "Play",
              label: "Audio Play",
              cropName: cropName,
              diseaseName: challenge?.challengeName,
              user_msisdn: authStore?.authData?.msisdn || null,
            });
          }}
        >
          <source src={`${baseURL}/uploads/audio_advisory/${challenge?.audio}`} />
          Your browser does not support the audio element.
        </audio>
      </div>

      {/* Crop Disease Section */}
      <div className="crop-disease-section mt-3 leading-8" style={{ direction: "rtl" }}>
        <p className="title mb-3 tracking-wider">{challenge?.challengeName}</p>
        <p className="description tracking-wider">{challenge?.challengeDesc}</p>
      </div>

      {/* Instructions Section */}
      <div className="d-none instruction-section" style={{ direction: "rtl" }}>
        <div className="product-info">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="28" height="28" rx="14" fill="#3988FF" />
            <path d="M17.175 13H11.825C11.7417 13.2 11.6417 13.3958 11.525 13.5875C11.4083 13.7792 11.2667 13.9417 11.1 14.075L11.825 22H17.175L17.9 14.075C17.7334 13.925 17.5917 13.7583 17.475 13.575C17.3584 13.3917 17.2584 13.2 17.175 13ZM11.55 9L11.275 10.1C11.3917 10.2333 11.4958 10.375 11.5875 10.525C11.6792 10.675 11.7583 10.8333 11.825 11H17.175C17.2417 10.8333 17.3209 10.675 17.4125 10.525C17.5042 10.375 17.6084 10.2333 17.725 10.1L17.45 9H11.55ZM11.825 24C11.3083 24 10.8584 23.8292 10.475 23.4875C10.0917 23.1458 9.87502 22.7167 9.82502 22.2L9.05002 13.55C9.03335 13.3833 9.06252 13.2292 9.13752 13.0875C9.21252 12.9458 9.32502 12.8333 9.47502 12.75C9.60835 12.6667 9.72502 12.5667 9.82502 12.45C9.92502 12.3333 9.97502 12.1917 9.97502 12.025C9.97502 11.875 9.94168 11.7375 9.87502 11.6125C9.80835 11.4875 9.70835 11.3833 9.57502 11.3C9.40835 11.2167 9.28335 11.0875 9.20002 10.9125C9.11668 10.7375 9.10002 10.5583 9.15002 10.375L9.80002 7.75C9.85002 7.51667 9.96668 7.33333 10.15 7.2C10.3334 7.06667 10.5417 7 10.775 7H13.5V6H13C12.7167 6 12.4792 5.90417 12.2875 5.7125C12.0959 5.52083 12 5.28333 12 5C12 4.71667 12.0959 4.47917 12.2875 4.2875C12.4792 4.09583 12.7167 4 13 4H16C16.2834 4 16.5209 4.09583 16.7125 4.2875C16.9042 4.47917 17 4.71667 17 5C17 5.28333 16.9042 5.52083 16.7125 5.7125C16.5209 5.90417 16.2834 6 16 6H15.5V7H18.225C18.4584 7 18.6625 7.06667 18.8375 7.2C19.0125 7.33333 19.125 7.51667 19.175 7.75L19.85 10.375C19.9 10.5583 19.8834 10.7375 19.8 10.9125C19.7167 11.0875 19.5917 11.2167 19.425 11.3C19.2917 11.3667 19.1875 11.4583 19.1125 11.575C19.0375 11.6917 19 11.825 19 11.975C19 12.1583 19.0459 12.3125 19.1375 12.4375C19.2292 12.5625 19.35 12.6667 19.5 12.75C19.65 12.8333 19.7667 12.9458 19.85 13.0875C19.9334 13.2292 19.9667 13.3833 19.95 13.55L19.175 22.175C19.125 22.6917 18.9084 23.125 18.525 23.475C18.1417 23.825 17.6917 24 17.175 24H11.825Z" fill="white" />
          </svg>

          <p className="title">کیمیائی سپرے کا نام</p>
        </div>
        <p className="description"> 20%فعال جزو Myclobutanil یا Azoxystrobin کے ساتھ فنگسائڈس</p>

        <button onClick={openInstructionModal} className="instruction-btn">
          <p>ہدایات کا اطلاق کریں</p>
          <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8334 5.33366H2.72002L6.44669 1.60699L5.50002 0.666992L0.166687 6.00033L5.50002 11.3337L6.44002 10.3937L2.72002 6.66699H10.8334V5.33366Z" fill="white" />
          </svg>
        </button>
      </div>

      <Divider mt={25} mx={50} />

      {/* Video Container */}
      {challenge?.videos.length > 0 && <div className="video-container">
        <p>ویڈیوز</p>

        {
          challenge?.videos.map((item, index) => (
            <LazyVideo key={index}>
              <CropSolutionVideo
                videoSrc={item?.videoUrl}
                title={item?.urduStage}
                tag={item?.urduTag}
              />
            </LazyVideo>
          ))
        }
      </div>}

      {/* Modal Instruction Modal Start */}
      <GenericModal isOpen={instructionModal} onClose={closeInstructionModal} customClass={"instruction-modal"}>
        <div className="instruction-modal" style={{ direction: "rtl" }}>
          {/* Header Section */}
          <div className="header flex items-center justify-between">
            <p className="title">ہدایات کا اطلاق کریں۔</p>
            <svg className="cursor-pointer" onClick={closeInstructionModal} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.0001 29.3327C23.3639 29.3327 29.3334 23.3631 29.3334 15.9993C29.3334 8.63555 23.3639 2.66602 16.0001 2.66602C8.63628 2.66602 2.66675 8.63555 2.66675 15.9993C2.66675 23.3631 8.63628 29.3327 16.0001 29.3327Z" fill="#D3D3D3" />
              <path d="M20 12L12 20" stroke="white" strokeWidth="2" strokeLinecap="round" />
              <path d="M12 12L20 20" stroke="white" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </div>

          {/* Content */}
          <div className="instructions">
            <p className="title">پتوں کے زنگ پر سپرے کیسے لگائیں</p>
            <div className="instruction-step">
              <div className="circle">1</div>
              <div className="text-container">
                <p className="title">اپنے فیلڈ کا دورہ کریں۔</p>
                <p className="description">ہم اسے ہفتے میں کم از کم 2 بار کرنے کی تجویز کرتے ہیں۔</p>
              </div>
            </div>
            <div className="instruction-step">
              <div className="circle">2</div>
              <div className="text-container">
                <p className="title">اپنے فیلڈ کا دورہ کریں۔</p>
                <p className="description">ہم اسے ہفتے میں کم از کم 2 بار کرنے کی تجویز کرتے ہیں۔</p>
              </div>
            </div>
            <div className="instruction-step">
              <div className="circle">3</div>
              <div className="text-container">
                <p className="title">اپنے فیلڈ کا دورہ کریں۔</p>
                <p className="description">ہم اسے ہفتے میں کم از کم 2 بار کرنے کی تجویز کرتے ہیں۔</p>
              </div>
            </div>
            <div className="instruction-step">
              <div className="circle">4</div>
              <div className="text-container">
                <p className="title">اپنے فیلڈ کا دورہ کریں۔</p>
                <p className="description">ہم اسے ہفتے میں کم از کم 2 بار کرنے کی تجویز کرتے ہیں۔</p>
              </div>
            </div>
            <div className="instruction-step">
              <div className="circle">5</div>
              <div className="text-container">
                <p className="title">اپنے فیلڈ کا دورہ کریں۔</p>
                <p className="description">ہم اسے ہفتے میں کم از کم 2 بار کرنے کی تجویز کرتے ہیں۔</p>
              </div>
            </div>
          </div>
        </div>
      </GenericModal>
      {/* Modal Instruction Modal End */}
    </div>
  )
}

export default CropDetailsDisease;