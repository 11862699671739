import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setActiveCrop, setActiveTab, setAddCropsDrawer, setSelectedCrops, setSowingDateModal, setTempCrop, updateSubcriberCrops } from '../store'
import cropsConfig from "../../../../configs/urdu.crops.config.json";
import tick from '../../../../assets/images/blue-tick.png'
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog/ConfirmationDialog';
import { deleteCrop, saveSubcriberCrops } from '../../../../API';
import moment from 'moment';
import ReactGA from "react-ga4";
const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

const tabs = [
    {
        eng: "crop",
        urd: "فصلیں"
    },
    {
        eng: "fruit",
        urd: "پھل"
    },
    {
        eng: "vegetable",
        urd: "سبزیاں"
    },
]

function filterSelectedCrops(subscriberCrops, selectedCrops) {
    // Filter out crops that are common between subscriberCrops and selectedCrops
    const remainingCrops = selectedCrops.filter(selectedCrop => {
        return !subscriberCrops.some(subscriberCrop => subscriberCrop.cropId === selectedCrop.cropId);
    });

    // If no remaining crops, return false, otherwise return the remaining crops
    return remainingCrops.length === 0 ? false : remainingCrops;
}

const AddCrop = ({ allCrops, setDrawer, activeCrop, activeTab, selectedCrops, subscriberCrops, cellNo, rerender, setRerender, setConfirmationAddCropModal }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const [removeCropConfirmationModal, setRemoveCropConfirmationModal] = useState(false)
    const [crop, setCrop] = useState(null);

    const [addedCrops, setAddedCrops] = useState([])

    useEffect(() => {
        // Combine arrays based on cropId and keep unique items
        const combinedMap = new Map();

        // Add items from the first array to the map
        subscriberCrops?.forEach(item => combinedMap.set(item.cropId, { ...item }));

        // Add/merge items from the second array to the map
        selectedCrops?.forEach(item => {
            if (combinedMap.has(item.cropId)) {
                // Merge the item if it exists in the map
                combinedMap.set(item.cropId, { ...combinedMap.get(item.cropId), ...item });
            } else {
                // Add the new unique item
                combinedMap.set(item.cropId, { ...item });
            }
        });

        // Convert map back to an array
        const combinedArray = Array.from(combinedMap.values());

        setAddedCrops(combinedArray)
    }, [selectedCrops])

    const handleRemoveCrop = (item) => {
        setRemoveCropConfirmationModal(true)
        setCrop(item)
    };

    const handleAddCrop = () => {
        const isCrops = filterSelectedCrops(subscriberCrops, selectedCrops)
        if (!isCrops) return
        const crops = isCrops.map((crop) => ({
            sowingDate: moment(crop.sowingDt).format("YYYY-MM-DD"),
            msisdn: cellNo,
            cropId: crop.cropId,
        }));
        setLoading(true)
        if (cellNo !== "03400000000") {
            saveSubcriberCrops(crops)
                .then(res => {
                    if (!res.data[0].success) return
                    // console.log("Subscriber Crops res > ", res.data[0])
                    dispatch(setAddCropsDrawer(false))
                    setConfirmationAddCropModal(true)

                    // Custom event for adding crops
                    res.data?.map(d => {
                        ReactGA.event("Crops Added", {
                            label: "Crops Added",
                            action: "click",
                            category: "crop",
                            user_msisdn: d.data?.cellno || null,
                            crop_name: d.data?.cropId || null,
                            sowing_date: d.data?.sowingDt || null,
                            crops_count: res.data?.length || null
                        });
                    })
                })
                .catch(err => console.log("Subscriber crops err > ", err))
                .finally(() => setLoading(false))
            return
        }
        dispatch(updateSubcriberCrops(addedCrops))
        dispatch(setAddCropsDrawer(false))
        setConfirmationAddCropModal(true)
        setLoading(false)
    }
    return (
        <div className='w-full'>
            {/* =========== Header ========== */}
            <div className="flex items-center justify-between">
                <svg
                    className="cursor-pointer"
                    onClick={setDrawer}
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M21 38.5C30.665 38.5 38.5 30.665 38.5 21C38.5 11.335 30.665 3.5 21 3.5C11.335 3.5 3.5 11.335 3.5 21C3.5 30.665 11.335 38.5 21 38.5Z"
                        fill="#777777"
                    />
                    <path
                        d="M26.25 15.75L15.75 26.25"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                    <path
                        d="M15.75 15.75L26.25 26.25"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                </svg>
                <p
                    style={{
                        whiteSpace: "normal",
                        direction: "rtl",
                        fontSize: "24px",
                        fontWeight: 500,
                        margin: "0px",
                    }}
                >
                    فصل شامل کریں۔
                </p>
            </div>

            {/* ========== Selected Crops ========= */}
            <div
                className="flex gap-2 px-2 pt-4 overflow-y-hidden overflow-x-scroll"
                style={{ direction: 'rtl' }}
            >
                {addedCrops?.map((crop, index) => (
                    <div
                        key={index}
                        className="w-14 flex flex-col items-center gap-2"
                    >
                        <div className="relative w-14 h-14">
                            <img
                                src={`${baseURL}/uploads/${crop?.image || `${crop?.cropId === "tobbaco" ? "tobacco" : crop?.cropId}.png`}`}
                                alt={crop?.cropName}
                                className='object-cover rounded-full'
                            />
                            <svg
                                onClick={() => handleRemoveCrop(crop)}
                                className="absolute top-0 -right-2 cursor-pointer"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.3335 22C17.8563 22 22.3335 17.5228 22.3335 12C22.3335 6.47715 17.8563 2 12.3335 2C6.81065 2 2.3335 6.47715 2.3335 12C2.3335 17.5228 6.81065 22 12.3335 22Z"
                                    fill="#0F2532"
                                />
                                <path
                                    d="M15.3335 9L9.3335 15"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                />
                                <path
                                    d="M9.3335 9L15.3335 15"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </div>
                        <p>{crop?.urduName || cropsConfig[crop.cropId]}</p>
                    </div>
                ))}
            </div>

            {/* ============ Crop Tabs ============ */}
            <div className='flex gap-2 mb-4 mt-2' style={{ direction: "rtl" }}>
                {
                    tabs.map((tab, index) => (
                        <div
                            key={index}
                            className={`${activeTab.urd == tab.urd && "bg-black text-white"} border rounded-full pt-1 pb-2 w-full text-center text-[14px]`}
                            onClick={() => dispatch(setActiveTab(tab))}
                        >
                            {tab.urd}
                        </div>
                    ))
                }
            </div>

            {/* ============ All Crops ============ */}
            <div className='grid grid-cols-3 gap-3' style={{ direction: 'rtl' }}>
                {
                    allCrops?.filter(c => c.cropType === activeTab.eng)?.length > 0 ?
                        allCrops?.filter(c => c.cropType === activeTab.eng)?.map((crop, index) => {
                            const isSelected = selectedCrops?.some((item) => item.cropId === crop.cropId);
                            return (
                                <div
                                    key={index}
                                    className='flex flex-col items-center'
                                    onClick={() => {
                                        if (isSelected) return
                                        dispatch(setTempCrop(crop));
                                        dispatch(setAddCropsDrawer(false))
                                        dispatch(setSowingDateModal(true))
                                    }}
                                >
                                    <div className='relative'>
                                        {isSelected && <img
                                            src={tick}
                                            alt="tick"
                                            className={`absolute -right-1 `}
                                            width={30}
                                        />}
                                        <img
                                            src={`${baseURL}/uploads/${crop.image}`}
                                            alt={crop.image}
                                            className={`rounded-full mb-2 ${isSelected && "border-[3px] border-[#007F3B]"}`}
                                            width={83}
                                            height={83}
                                        />
                                    </div>
                                    <p className='text-[18px]'>{crop.urduName}</p>
                                </div>
                            )
                        })
                        :
                        <div className='text-center col-span-3 pb-4'>یہ سہولت جلد میسّر کی جائے گی۔</div>
                }
            </div>

            {/* ============== Button ============ */}
            <button
                disabled={loading || selectedCrops?.length == 0}
                onClick={handleAddCrop}
                className={`${(loading || selectedCrops?.length == 0) ? "bg-gray-200 text-gray-400" : "bg-[#007F3B] text-white"} w-full rounded text-[18px] h-[54px] active:translate-y-[1px]`}
            >
                منتخب کریں
            </button>

            {/* ================== Confirmation dialog to remove crop ================== */}
            <ConfirmationDialog
                show={removeCropConfirmationModal}
                handleClick={() => {
                    const tempCrop = selectedCrops?.find((c) => c.cropId === crop.cropId);
                    if (tempCrop) {
                        let crops = selectedCrops?.filter(c => c.cropId !== tempCrop.cropId)
                        dispatch(setSelectedCrops(crops));
                    }
                    const SubscriberCrop = subscriberCrops?.find((c) => c.cropId === crop.cropId);
                    if (SubscriberCrop) {
                        if (cellNo !== "03400000000") {
                            if (SubscriberCrop.cropId === activeCrop.cropId) dispatch(setActiveCrop(subscriberCrops.filter((c) => c.cropId !== crop.cropId)[0]))
                            deleteCrop(SubscriberCrop.cropId, cellNo).then((res) => {
                                // console.log("res", res)
                                setRerender(!rerender)
                            }).catch((err) => {
                                console.log("err", err)
                            })
                        } else {
                            let filteredCrop = subscriberCrops.filter((c) => c.cropId !== crop.cropId)
                            dispatch(updateSubcriberCrops(filteredCrop))
                            dispatch(setActiveCrop(filteredCrop[0]));
                            setRerender(!rerender)
                        }
                    }
                    setRemoveCropConfirmationModal(false)
                }}
                onHide={() => setRemoveCropConfirmationModal(false)}
                content={"کیا آپ یہ فصل ہٹانا چاہتے ہیں؟"}
            />

        </div>
    )
}

export default AddCrop