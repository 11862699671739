import React from 'react'
import cropsConfig from "../../../../configs/urdu.crops.config.json";
import { useDispatch } from 'react-redux';
import { setActiveCrop, setCropObject, setSowingDateModal, setTempCrop } from '../store';
import { applyInterFontToDigits } from '../../../CropDetails/CropDetails';
import { formatDateWithUrduMonth } from '../../../../utils/extras';
import { useNavigate } from 'react-router-dom';
const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

const CropDetails = ({ subscriberCrops, activeCrop, handleAddCropDrawer }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <>
            <div className='flex items-center justify-between'>
                <button
                    onClick={handleAddCropDrawer}
                    className='active:translate-y-[1px] text-[16px] text-white bg-[#007F3B] rounded-[8px] flex items-center gap-2 p-3'
                >
                    فصل شامل کریں
                    <img src='plus-icon.svg' className='w-5 h-5' />
                </button>
                <h4 className='font-bold'>آپ کی فصلیں</h4>
            </div>

            <div className='flex gap-2 my-3 overflow-x-auto' style={{ direction: 'rtl' }}>
                {
                    subscriberCrops?.map((item, index) => {
                        return (
                            <button
                                key={index}
                                className={`text-[16px] border-2 border-[2px solid rgba(0,0,0,0.24)] rounded-[18px] pt-[10px] px-[30px] ${activeCrop?.cropId === item.cropId && "bg-black text-white"}`}
                                onClick={() => dispatch(setActiveCrop(item))}
                            >
                                <p>{cropsConfig[item.cropId]}</p>
                            </button>
                        )
                    })
                }
            </div>

            {activeCrop && <div className='bg-white rounded p-3 cursor-pointer' onClick={() => {
                dispatch(setCropObject(activeCrop))
                navigate(`/crop-details/${activeCrop.cropId}`)
            }}>
                <div className='flex items-center gap-3'>
                    <div className='flex-1 text-right'>
                        <p className='text-[24px]'>{activeCrop?.urduName}</p>
                        <div className='flex items-center justify-end gap-2'>
                            {activeCrop?.sowingDt && <p
                                className="cursor-pointer bg-[#ECF9FF] rounded-full py-[8px] px-[10px] text-xs"
                                style={{ direction: "rtl" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(setTempCrop(activeCrop))
                                    dispatch(setSowingDateModal(true))
                                }}
                                dangerouslySetInnerHTML={{ __html: applyInterFontToDigits(formatDateWithUrduMonth(activeCrop?.sowingDt)) }}
                            />}
                            <p className='text-xs'>بوائی کی تاریخ</p>
                        </div>
                    </div>
                    <img
                        src={`${baseURL}/uploads/${activeCrop?.image}`}
                        className='rounded-full'
                        width={72}
                        height={72}
                        alt={activeCrop?.cropId}
                    />
                </div>

                <div className="no-underline bg-black text-white w-full rounded-[8px] flex flex-row-reverse items-center justify-center gap-2 py-3">
                    مزید تفصیل دیکھیں
                    <svg
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className='w-3 h-3'
                    >
                        <path
                            d="M10.8334 5.33366H2.72002L6.44669 1.60699L5.50002 0.666992L0.166687 6.00033L5.50002 11.3337L6.44002 10.3937L2.72002 6.66699H10.8334V5.33366Z"
                            fill="white"
                        />
                    </svg>
                </div>
            </div>}
        </>
    )
}

export default CropDetails